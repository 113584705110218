// DotGrid.tsx
import { useEffect, useRef, useState } from 'react'

const SYMBOL_SIZE = 3
const GLOW_DURATION = 2500
const LOOP_INTERVAL = 12000
const GRID_GAP = 40
const FADE_OUT_DURATION = 2500
const LINE_OPACITY = 0.15 // Constant opacity for lines

// interface GridItemProps {
//     x: number
//     y: number
//     opacity: number
//     distanceFromCenter: number
//     hasGlowed: boolean
//     isVisible: boolean
// }

// interface LineProps {
//     x1: number
//     y1: number
//     x2: number
//     y2: number
//     isVisible: boolean
// }

export default function DotGrid() {
    const [items, setItems] = useState([])
    const [lines, setLines] = useState([])
    const [isDotsPattern, setIsDotsPattern] = useState(false) // Changed initial state to false for plus signs first
    const animationFrameRef = useRef()
    const startTimeRef = useRef(0)
    const cycleCountRef = useRef(0)

    const calculateItems = () => {
        const centerX = window.innerWidth / 2
        const centerY = window.innerHeight / 2
        const newItems = []

        const cols = Math.floor(window.innerWidth / GRID_GAP)
        const rows = Math.floor(window.innerHeight / GRID_GAP)
        const offsetX = (window.innerWidth - (cols - 1) * GRID_GAP) / 2
        const offsetY = (window.innerHeight - (rows - 1) * GRID_GAP) / 2

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                const x = offsetX + col * GRID_GAP
                const y = offsetY + row * GRID_GAP
                const dx = x - centerX
                const dy = y - centerY
                const distance = Math.sqrt(dx * dx + dy * dy)

                newItems.push({
                    x,
                    y,
                    opacity: 0,
                    distanceFromCenter: distance,
                    hasGlowed: false,
                    isVisible: false,
                })
            }
        }
        return newItems
    }

    const calculateLines = () => {
        const newLines = []
        const cols = Math.floor(window.innerWidth / GRID_GAP)
        const rows = Math.floor(window.innerHeight / GRID_GAP)
        const offsetX = (window.innerWidth - (cols - 1) * GRID_GAP) / 2
        const offsetY = (window.innerHeight - (rows - 1) * GRID_GAP) / 2

        // Create horizontal lines
        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols - 1; col++) {
                const x1 = offsetX + col * GRID_GAP
                const y1 = offsetY + row * GRID_GAP
                const x2 = x1 + GRID_GAP
                const y2 = y1
                newLines.push({ x1, y1, x2, y2, isVisible: false })
            }
        }

        // Create vertical lines
        for (let row = 0; row < rows - 1; row++) {
            for (let col = 0; col < cols; col++) {
                const x1 = offsetX + col * GRID_GAP
                const y1 = offsetY + row * GRID_GAP
                const x2 = x1
                const y2 = y1 + GRID_GAP
                newLines.push({ x1, y1, x2, y2, isVisible: false })
            }
        }

        return newLines
    }

    useEffect(() => {
        const handleResize = () => {
            setItems(calculateItems())
            setLines(calculateLines())
        }

        setItems(calculateItems())
        setLines(calculateLines())
        window.addEventListener("resize", handleResize)

        const animate = (timestamp) => {
            if (!startTimeRef.current) startTimeRef.current = timestamp
            const elapsed = (timestamp - startTimeRef.current) % LOOP_INTERVAL

            // Switch pattern at the end of each cycle
            if (
                elapsed < 20 &&
                cycleCountRef.current !==
                    Math.floor(
                        (timestamp - startTimeRef.current) / LOOP_INTERVAL
                    )
            ) {
                cycleCountRef.current = Math.floor(
                    (timestamp - startTimeRef.current) / LOOP_INTERVAL
                )
                setIsDotsPattern((prev) => !prev)
                if (!isDotsPattern) {
                    // Changed condition to clear lines when switching from plus to dots
                    setLines((prev) =>
                        prev.map((line) => ({ ...line, isVisible: false }))
                    )
                }
            }

            const maxDistance = Math.sqrt(
                Math.pow(window.innerWidth / 2, 2) +
                    Math.pow(window.innerHeight / 2, 2)
            )

            if (elapsed > GLOW_DURATION) {
                // Fade out phase
                const fadeOutElapsed = elapsed - GLOW_DURATION
                const fadeOutProgress = Math.min(
                    fadeOutElapsed / FADE_OUT_DURATION,
                    1
                )

                if (isDotsPattern) {
                    // Radial fade out for dots
                    const fadeOutRadius = fadeOutProgress * maxDistance
                    setItems((prev) =>
                        prev.map((item) => ({
                            ...item,
                            opacity: item.hasGlowed
                                ? item.distanceFromCenter <= fadeOutRadius
                                    ? 0
                                    : item.opacity
                                : item.opacity,
                            hasGlowed: item.hasGlowed,
                            isVisible: false,
                        }))
                    )
                } else {
                    // Square fade out for plus signs
                    const fadeOutSize =
                        fadeOutProgress *
                        Math.max(window.innerWidth, window.innerHeight)
                    const centerX = window.innerWidth / 2
                    const centerY = window.innerHeight / 2

                    setItems((prev) => {
                        const newItems = prev.map((item) => {
                            const isInFadeOutZone =
                                Math.abs(item.x - centerX) <= fadeOutSize / 2 &&
                                Math.abs(item.y - centerY) <= fadeOutSize / 2
                            return {
                                ...item,
                                opacity: item.hasGlowed
                                    ? isInFadeOutZone
                                        ? 0
                                        : item.opacity
                                    : item.opacity,
                                hasGlowed: item.hasGlowed,
                                isVisible: !isInFadeOutZone && item.hasGlowed,
                            }
                        })

                        // Update lines visibility based on the active area
                        setLines((prev) =>
                            prev.map((line) => ({
                                ...line,
                                isVisible:
                                    Math.abs(line.x1 - centerX) >
                                        fadeOutSize / 2 ||
                                    Math.abs(line.y1 - centerY) >
                                        fadeOutSize / 2,
                            }))
                        )

                        return newItems
                    })
                }
            } else {
                // Fade in phase
                if (isDotsPattern) {
                    // Radial fade in for dots
                    const progress = (elapsed / GLOW_DURATION) * maxDistance
                    setItems((prev) =>
                        prev.map((item) => {
                            const inActiveZone =
                                Math.abs(item.distanceFromCenter - progress) <
                                GRID_GAP * 2

                            if (inActiveZone && !item.hasGlowed) {
                                return {
                                    ...item,
                                    opacity: Math.random() < 0.4 ? 1 : 0.15,
                                    hasGlowed: true,
                                    isVisible: true,
                                }
                            }

                            if (
                                item.distanceFromCenter >
                                progress + GRID_GAP * 2
                            ) {
                                return {
                                    ...item,
                                    opacity: 0,
                                    hasGlowed: false,
                                    isVisible: false,
                                }
                            }

                            return item
                        })
                    )
                } else {
                    // Square fade in for plus signs
                    const progress = elapsed / GLOW_DURATION
                    const fadeInSize =
                        progress *
                        Math.max(window.innerWidth, window.innerHeight)
                    const centerX = window.innerWidth / 2
                    const centerY = window.innerHeight / 2

                    setItems((prev) => {
                        const newItems = prev.map((item) => {
                            const distanceX = Math.abs(item.x - centerX)
                            const distanceY = Math.abs(item.y - centerY)
                            const inActiveZone =
                                Math.abs(
                                    Math.max(distanceX, distanceY) -
                                        fadeInSize / 2
                                ) < GRID_GAP

                            if (inActiveZone && !item.hasGlowed) {
                                return {
                                    ...item,
                                    opacity: Math.random() < 0.4 ? 1 : 0.15,
                                    hasGlowed: true,
                                    isVisible: true,
                                }
                            }

                            return {
                                ...item,
                                isVisible: item.hasGlowed,
                            }
                        })

                        // Update lines visibility based on the active area
                        setLines((prev) =>
                            prev.map((line) => ({
                                ...line,
                                isVisible:
                                    Math.abs(
                                        Math.max(
                                            Math.abs(line.x1 - centerX),
                                            Math.abs(line.y1 - centerY)
                                        ) -
                                            fadeInSize / 2
                                    ) < GRID_GAP,
                            }))
                        )

                        return newItems
                    })
                }
            }

            animationFrameRef.current = requestAnimationFrame(animate)
        }

        animationFrameRef.current = requestAnimationFrame(animate)

        return () => {
            window.removeEventListener("resize", handleResize)
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current)
            }
        }
    }, [isDotsPattern])

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "#FFF9F5",
            }}
        >
            {/* Render lines */}
            {!isDotsPattern && (
                <svg
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                    }}
                >
                    {lines.map((line, i) => (
                        <line
                            key={i}
                            x1={line.x1}
                            y1={line.y1}
                            x2={line.x2}
                            y2={line.y2}
                            stroke="black"
                            strokeWidth="0.5"
                            opacity={line.isVisible ? LINE_OPACITY : 0}
                            style={{
                                transition: "opacity 0.5s ease",
                            }}
                        />
                    ))}
                </svg>
            )}

            {/* Render dots/plus signs */}
            {items.map((item, i) => (
                <div
                    key={i}
                    style={{
                        position: "absolute",
                        left: item.x - SYMBOL_SIZE / 2,
                        top: item.y - SYMBOL_SIZE / 2,
                        width: SYMBOL_SIZE,
                        height: SYMBOL_SIZE,
                        opacity: item.opacity,
                        transition: "opacity 0.5s ease",
                        ...(isDotsPattern
                            ? {
                                  borderRadius: "50%",
                                  backgroundColor: "black",
                              }
                            : {
                                  backgroundColor: "transparent",
                                  "&::before": {
                                      content: "'+'",
                                      position: "absolute",
                                      color: "black",
                                      fontSize: `${SYMBOL_SIZE * 2}px`,
                                      lineHeight: 1,
                                      transform: "translate(-50%, -50%)",
                                  },
                              }),
                    }}
                >
                    {!isDotsPattern && (
                        <span
                            style={{
                                position: "absolute",
                                top: "-3px",
                                left: "-3px",
                                fontSize: "10px",
                                lineHeight: 1,
                            }}
                        >
                            +
                        </span>
                    )}
                </div>
            ))}
        </div>
    )
}