import React from 'react';
import DotGrid from './DotGrid';
const LandingPage = () => {
  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center px-4">
      <DotGrid />
      
      {/* Content Layer */}
      <div className="relative z-10 max-w-3xl mx-auto text-center">
        {/* Top Text */}
        <p className="text-gray-600 mb-16">
          10x design. 10x growth. 10x impact.
        </p>

        {/* Main Content */}
        <div className="mb-12">
          <h1 className="text-5xl font-bold mb-6 leading-tight">
            Is your site holding<br />back your growth?
          </h1>
          
          <p className="text-lg mb-8">
            Get an actionable analysis <em>from a product design team.</em>
          </p>

          {/* URL Input Form */}
          <div className="flex max-w-xl mx-auto mb-6">
            <input
              type="url"
              placeholder="https://your.link"
              className="flex-1 px-6 py-3 rounded-l-full border border-r-0 border-gray-300 focus:outline-none focus:border-black"
            />
            <button className="px-8 py-3 bg-black text-white rounded-r-full hover:bg-gray-800 transition-colors">
              Analyze
            </button>
          </div>

          <p className="text-gray-500 mb-12">
            All missed opportunities in one single analysis.
          </p>

          {/* Checkmarks */}
          <div className="space-y-3 text-gray-600 mb-8">
            <p>✓ How your customers view it?</p>
            <p>✓ Business's USP highlighted?</p>
            <p>✓ Is it pleasing to the eyes?</p>
          </div>

          <p className="text-sm text-gray-500">
            GET STARTED IN MINUTES
          </p>
        </div>

        {/* Footer */}
        <p className="text-sm text-gray-500 max-w-2xl mx-auto">
          A micro design service fulfilled by a team who has designed at scale for JPMorganChase, Microsoft, Coca-Cola, and Y-Combinator backed startups.
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
